'use strict';

angular.module('enervexSalesappApp').controller('JobsCtrl', function($scope, $stateParams, JobService, Job, FavoriteJob, Auth, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.offset = 0;
	$scope.jobs = []
	$scope.limit = 100;
	JobService.getAccount().$promise.then(function(res){
		$scope.account = res;
		$scope.permissions = Auth.accountPermission($scope.account);
	});
	$scope.jobStatuses = JobService.getStatus();
	$scope.favorite = function(job){
		FavoriteJob.save({
			accountId: $stateParams.accountId,
		}, {
			job: job._id
		}).$promise.then(function(res){
			job.favorite = true;
			fetchFavorites();
		});
	}
	$scope.unfavorite = function(job){
		var favorite = _.find($scope.favorites, function(favorite){
			return favorite.job._id == job._id;
		});
		FavoriteJob.remove({
			id: job._id,
			accountId: $stateParams.accountId
		}).$promise.then(function(res){
			job.favorite = false;
			fetchFavorites();
		});
	}
	function updateFavorites() {
		_.each($scope.jobs, function(job){
			var favorite =  _.find($scope.favorites, function(favorite){
				return job._id == favorite.job._id
			})
			job.favorite = (favorite) ? true : false;
		})
	}
	function fetchFavorites() {
		FavoriteJob.query({
			accountId: $stateParams.accountId
		}).$promise.then(function(result){
			$scope.favorites = result;
			updateFavorites();
		})
	}
	function fetchJobs() {
		Job.query({
			offset:$scope.offset,
			limit: $scope.limit,
			accountId: $stateParams.accountId,
			sort: '-jobNumber'
		}).$promise.then(function(jobs){
			if (jobs.length > 0) {
				$scope.jobs = $scope.jobs.concat(jobs);
				$scope.offset = $scope.offset + $scope.limit;
				updateFavorites()
				fetchJobs();
			} else {
				updateFavorites()
			}
		});
	}

	$scope.changeFilter = function(val){
		if (!val || val == ''){
			$scope.filter = null;
		} else {
			$scope.filter = new RegExp(val, "i");
		}
	}
	$scope.myFilter = function (item) {
		if (!$scope.filter){
			return true;
		} else {
			return $scope.filter.test(item.name) || (item.jobCode && $scope.filter.test(item.jobCode)) || (item.externalReference && $scope.filter.test(item.externalReference)) ;
		}
	};

	$scope.sortType = 'jobNumber';
	$scope.sortReverse = true;

	fetchFavorites();
	fetchJobs()
});
